import PropTypes from "prop-types"
import React, { useReducer, useMemo } from "react"

import { cloneDeep, random } from "lodash"

import ReversiCell from "./cell"

// const MODES = ["offline", "offline-ai-random"]

const initialState = {
  board: [
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 1, 0, 0, 0],
    [0, 0, 0, 1, 1, 0, 0, 0],
    [0, 0, 0, 2, 1, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
  ],
  nextPlayer: 2,
  firstPlayerSkipCount: 0,
  secondPlayerSkipCount: 0,
  waitingForAI: false,
  showPossibleMoves: true,
  mode: "offline-ai-random",
}

function canPut(state, x, y) {
  if (state.board[x][y] !== 0) return false
  for (let [dx, dy] of [
    [-1, -1],
    [-1, 0],
    [-1, 1],
    [0, -1],
    [0, 1],
    [1, -1],
    [1, 0],
    [1, 1],
  ]) {
    let a = x + dx
    let b = y + dy
    if (
      0 <= a &&
      a < 8 &&
      0 <= b &&
      b < 8 &&
      state.board[a][b] === 3 - state.nextPlayer
    ) {
      for (let i = 0; i < 8; i++) {
        a += dx
        b += dy
        if (a < 0 || b < 0 || a >= 8 || b >= 8 || state.board[a][b] === 0) break
        if (state.board[a][b] === state.nextPlayer) return true
      }
    }
  }

  return false
}

const put = (board, x, y, value) => {
  board[x][y] = value

  for (let [dx, dy] of [
    [-1, -1],
    [-1, 0],
    [-1, 1],
    [0, -1],
    [0, 1],
    [1, -1],
    [1, 0],
    [1, 1],
  ]) {
    let a = x + dx
    let b = y + dy
    let shouldFlip = false
    if (0 <= a && a < 8 && 0 <= b && b < 8 && board[a][b] === 3 - value) {
      for (let i = 0; i < 8; i++) {
        a += dx
        b += dy
        if (a < 0 || b < 0 || a >= 8 || b >= 8 || board[a][b] === 0) break
        if (board[a][b] === value) {
          shouldFlip = true
          break
        }
      }
    }

    if (shouldFlip) {
      for (let i = 0; i < 8; i++) {
        a -= dx
        b -= dy
        if (board[a][b] === value) break
        board[a][b] = value
      }
    }
  }

  return board
}

const get_ai_put_pos = state => {
  let candidates = []
  for (let i = 0; i < 8; i++) {
    for (let j = 0; j < 8; j++) {
      if (canPut(state, i, j)) candidates.push([i, j])
    }
  }

  if (candidates.length === 0) return [-1, -1]

  const i = random(candidates.length - 1)
  return candidates[i]
}

function reducer(state, action) {
  let board = cloneDeep(state.board)
  switch (action.type) {
    case "PUT":
      const { x, y, value } = action.payload
      if (!canPut(state, x, y)) return { ...state }
      board = put(board, x, y, value)
      return { ...state, board, nextPlayer: 3 - value }
    case "AI_PUT":
      const [i, j] = get_ai_put_pos(state)
      if (i < 0 || j < 0) return { ...state }
      board = put(board, i, j, state.nextPlayer)
      return {
        ...state,
        board,
        nextPlayer: 3 - state.nextPlayer,
        waitingForAI: false,
      }
    case "WAIT_FOR_AI":
      return { ...state, waitingForAI: true }
    default:
      throw new Error()
  }
}

const getPossiblePutCount = state => {
  let res = 0
  for (let i = 0; i < 8; i++) {
    for (let j = 0; j < 8; j++) {
      if (canPut(state, i, j)) res += 1
    }
  }
  return res
}

const ReversiGame = ({ userName }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const count = value => {
    let res = 0
    for (let i = 0; i < 8; i++) {
      for (let j = 0; j < 8; j++) {
        if (state.board[i][j] === value) res += 1
      }
    }
    return res
  }

  return (
    <div style={{ width: "32rem", margin: "0 auto" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <ReversiCell value={1} count={count(1)} />
          <ReversiCell value={2} count={count(2)} />
        </div>
        <div style={{ display: "flex" }}>
          <h3
            style={{
              margin: "0",
              lineHeight: "4rem",
              marginRight: "1rem",
            }}
          >
            Next Player:
          </h3>
          <ReversiCell value={state.nextPlayer} />
        </div>
      </div>
      <br />
      {state.board.map((row, rIndex) => {
        return (
          <div
            key={rIndex}
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {row.map((cell, cIndex) => (
              <div
                style={{
                  width: "4rem",
                  height: "4rem",
                  backgroundColor:
                    (rIndex + cIndex) % 2 === 0 ? "#45a749" : "green",
                }}
                key={cIndex}
              >
                <div
                  onClick={() => {
                    dispatch({
                      type: "PUT",
                      payload: {
                        x: rIndex,
                        y: cIndex,
                        value: state.nextPlayer,
                      },
                    })

                    if (state.mode === "offline-ai-random") {
                      dispatch({
                        type: "WAIT_FOR_AI",
                      })
                      setTimeout(() => {
                        dispatch({
                          type: "AI_PUT",
                          payload: {},
                        })
                      }, 200)
                    }
                  }}
                >
                  <ReversiCell
                    value={cell}
                    showAllowed={
                      state.showPossibleMoves &&
                      !state.waitingForAI &&
                      canPut(state, rIndex, cIndex)
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        )
      })}
      <br />
      {getPossiblePutCount(state) === 0 ? (
        <div>
          No moves left.
          {count(1) > count(2) && " White wins."}
          {count(1) < count(2) && " Black wins."}
          {count(1) === count(2) && " It's a tie!"}
        </div>
      ) : (
        <div style={{ display: "flex" }}></div>
      )}
    </div>
  )
}

ReversiGame.propTypes = {
  userName: PropTypes.string,
}

ReversiGame.defaultProps = {
  userName: ``,
}

export default ReversiGame
