import PropTypes from "prop-types"
import React from "react"
import "./game.scss"
import cx from "classnames"

const ReversiCell = ({ value, showAllowed, count }) => {
  return (
    <div
      style={{ height: "4rem", cursor: showAllowed ? "pointer" : "inherit" }}
    >
      {value !== 0 && (
        <div
          className={cx("reversi--cell", "reversi--cell-white", {
            "reversi--cell--visible": value === 1,
          })}
          style={{
            backgroundImage:
              value === 1
                ? "linear-gradient(rgb(170, 170, 170), rgb(238, 238, 238))"
                : "linear-gradient(#3a3a3a, #111)",
            border: "0.3rem solid " + (value === 1 ? "#bababa" : "#222"),
          }}
        >
          {count > 0 && (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  lineHeight: "3rem",
                  margin: "0 auto",
                  color: value !== 1 ? "white" : "black",
                }}
              >
                {count}
              </div>
            </div>
          )}
        </div>
      )}
      {showAllowed && (
        <div
          style={{
            margin: "0.2rem",
            width: "3rem",
            height: "3rem",
            border: "0.3rem solid #777",
            borderRadius: "100%",
          }}
        ></div>
      )}
    </div>
  )
}

ReversiCell.propTypes = {
  value: PropTypes.number,
  count: PropTypes.number,
  showAllowed: PropTypes.bool,
}

ReversiCell.defaultProps = {
  value: 0,
  showAllowed: false,
  count: 0,
}

export default ReversiCell
