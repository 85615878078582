import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ReversiGame from "../components/reversi/game"

const SecondPage = () => (
  <Layout>
    <SEO title="Reversi" />
    <h3 style={{ lineHeight: "4rem" }}>Reversi</h3>
    <hr />
    <ReversiGame />
  </Layout>
)

export default SecondPage
